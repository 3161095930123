import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import {useCollapse} from "react-collapsed";
import image2 from "../images/1on1page/image3.png";
import image1 from "../images/1on1page/image1.jpg";


function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function SmallGroupTraining(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Semi Private Training</title>
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Semi Private Training"}>
                <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                    <div className={"flex lg:flex-nowrap flex-wrap w-fit items-center gap-4 p-4 lg:pt-4 pt-0"}>
                        <img className={"lg:w-1/2 w-full h-full rounded-2xl"} src={small1}/>
                        <img className={"lg:w-1/2 w-full h-full rounded-2xl"} src={small2}/>

                    </div>
                    <h2 className={"text-3xl text-center pb-7 pt-5"}>Semi-private training is individualized for you and progresses at your own pace!</h2>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>No more feeling intimidated or compelled to do something you don’t want to do just because someone next you is doing it. You will have the help of the coach to develop and progress your individualized program. You can have all the fun of working out with others while working to meet YOUR individual goals.</p>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>In order to individualize your program you first will be seen 1-on-1 with one of our clinicians for an “Initial Exam” where we can make sure training in a small group of 2-4 people is best for you. Here we determine your safe starting point and find group times that work best for you.</p>
                    <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/nWaBmjamEHM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <a className={"mx-auto mb-8"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Sign Me Up!</button></a>

                    <Collapsible title={"Have a community to hold you accountable"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  lg:px-4 mx-auto"}>Do you find it hard to stick to a routine by yourself? Do you enjoy having others with you who are chasing their health goals just like you are? Small group training was made for you. Having others to workout with can help keep you accountable so you stay consistent long enough to see long term results. Our coaches will help guide you along the way to ensure you are progressing towards your goals.
                            </p>
                        </div>
                    </Collapsible>

                    <Collapsible title={"Get all the benefits of 1-on-1 coaching but at a much lower price"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  lg:px-4 mx-auto"}>Personal training and 1-on-1 fitness coaching is expensive, we get it! This is why we have created the option of semi-private training or small group training. This gives you all the individualization of personal training without the high dollar cost. Plus you get to sweat with people who support you and are leveling up right alongside you. That’s called a win-win.
                            </p>

                            </div>
                    </Collapsible>

                    <Collapsible title={"Bring the family together!"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  lg:px-4 mx-auto"}>Nothing brings a family together more than learning how to become healthier TOGETHER. Invest in making health a priority in your family so everyone can live a long and healthy life. We love working with families and invite you to bring your loved ones in to start your fitness journey together. </p>
                            </div>
                    </Collapsible>

                    <Collapsible title={"Become confident working out again"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  lg:px-4 mx-auto"}>You no longer need to avoid hanging out with friends and family when they are doing something active. With the support of a coach you don’t need to be afraid if you are doing exercises right or wrong anymore. As groups are limited to 4, the coach is able to be hands on and help with any questions or concerns you may have. </p>
                            </div>
                    </Collapsible>

                    <Collapsible title={"Moms, it is time to decompress and take some time for yourself "}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 lg:px-4 mx-auto"}>Sweat together with a group of your friends so you can get out of the house and take a breather from the kids. Make social hour a fitness hour so you can release some stress of being an uber driver and super mom. Get a group of 2-4 friends or family together and let's have some fun. Learn how to become healthier and get some well-earned time to yourself.</p>


                             </div>
                    </Collapsible>
                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Join the Team!</button></a>

                </div>
            </Hero>
            

            


            <Footer/>
        </div>
    )
}

export default SmallGroupTraining